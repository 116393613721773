// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fribourg-js": () => import("./../../../src/pages/fribourg.js" /* webpackChunkName: "component---src-pages-fribourg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-neuchatel-js": () => import("./../../../src/pages/neuchatel.js" /* webpackChunkName: "component---src-pages-neuchatel-js" */),
  "component---src-pages-serrurier-geneve-js": () => import("./../../../src/pages/serrurier-geneve.js" /* webpackChunkName: "component---src-pages-serrurier-geneve-js" */),
  "component---src-pages-serrurier-lausanne-js": () => import("./../../../src/pages/serrurier-lausanne.js" /* webpackChunkName: "component---src-pages-serrurier-lausanne-js" */),
  "component---src-pages-serrurier-montreux-js": () => import("./../../../src/pages/serrurier-montreux.js" /* webpackChunkName: "component---src-pages-serrurier-montreux-js" */),
  "component---src-pages-services-blindage-de-porte-js": () => import("./../../../src/pages/services/blindage-de-porte.js" /* webpackChunkName: "component---src-pages-services-blindage-de-porte-js" */),
  "component---src-pages-services-changement-serrure-js": () => import("./../../../src/pages/services/changement-serrure.js" /* webpackChunkName: "component---src-pages-services-changement-serrure-js" */),
  "component---src-pages-services-coffre-fort-js": () => import("./../../../src/pages/services/coffre-fort.js" /* webpackChunkName: "component---src-pages-services-coffre-fort-js" */),
  "component---src-pages-services-double-de-cle-js": () => import("./../../../src/pages/services/double-de-cle.js" /* webpackChunkName: "component---src-pages-services-double-de-cle-js" */),
  "component---src-pages-services-installation-porte-garage-js": () => import("./../../../src/pages/services/installation-porte-garage.js" /* webpackChunkName: "component---src-pages-services-installation-porte-garage-js" */),
  "component---src-pages-services-ouverture-porte-durgence-js": () => import("./../../../src/pages/services/ouverture-porte-durgence.js" /* webpackChunkName: "component---src-pages-services-ouverture-porte-durgence-js" */),
  "component---src-pages-services-rideau-metallique-js": () => import("./../../../src/pages/services/rideau-metallique.js" /* webpackChunkName: "component---src-pages-services-rideau-metallique-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-valais-js": () => import("./../../../src/pages/valais.js" /* webpackChunkName: "component---src-pages-valais-js" */),
  "component---src-pages-vaud-js": () => import("./../../../src/pages/vaud.js" /* webpackChunkName: "component---src-pages-vaud-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tarifs-template-js": () => import("./../../../src/templates/tarifs-template.js" /* webpackChunkName: "component---src-templates-tarifs-template-js" */)
}

